<template>
<v-container class="login-wrapper">
    <div class="login-content">
        <p>手机号登录</p>
        <div>
            <div class="input-item">
                <span>手机号</span>
                <input 
                    type="tel"
                    v-model="phone"
                    maxlength="11"
                />
            </div>
            <div class="input-item">
                <input 
                placeholder="验证码"
                    v-model="pass"
                />
                <v-btn 
                color="primary"
                :disabled="disableMessage"
                @click="sendMessage"
                depressed>
                    {{messageInfo}}
                </v-btn>
            </div>
        </div>
        <v-btn
            class="button-login"
            rounded
            depressed
            color="primary"
            @click="login"
        >
            登录
        </v-btn>
    </div>
</v-container>
</template>

<script>
import {sendLoginMessage, phoneLogin, getUserInfo} from '@/service/service';
const phoneReg = /^1\d{10}$/;

export default {
    name: 'Login',
    data() {
        return {
            messageInfo: '发送验证码',
            auth_time: 30,
            disableMessage: false,
            phone: '',
            pass: '',
            key: '',
            nextHref: '',
        }
    },
    beforeMount() {
        const param = this.$route.query;
        let netHref = param.nex;
        if (netHref!= undefined && netHref!= '') {
            this.nextHref = decodeURIComponent(netHref)
            console.log('this.nextHref', this.nextHref)
        }
    },
    watch: {
        phone(nv, ov) {
            const reg = /^\d*$/;
            if (!reg.test(nv)) {
                this.phone = ov;
            }
        }
    },
    methods: {
        login() {
            if (!this.phone) {
                this.$toast.error('请输入手机号码');
                return
            }
            const phone = this.phone.replace(/\s/g, '')
            if (!phoneReg.test(phone)) {
                this.$toast.error('请输入正确的手机号码');
                return
            }
            if (!this.pass) {
                this.$toast.error('请输入验证码');
                return
            }

            this.$loading.show();
            phoneLogin({
                userName: phone,
                captchaIdentity: this.pass,
                key: this.key,
            }).then(res => {
                console.log('phoneLogin', res);
                this.$loading.hide();
                localStorage.setItem('token', res.data);
                localStorage.setItem('userInfo', JSON.stringify({ phone: this.phone }))
                this.getUserInfo();
                if (this.nextHref != '') {
                    window.location.href = this.nextHref;
                } else {
                    this.$router.push('/home');
                }
                
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
        },
        getUserInfo() {
            getUserInfo().then(res => {
                let userInfo = localStorage.getItem('userInfo');
                userInfo = userInfo ? JSON.parse(userInfo) : {};
                userInfo = {
                    ...userInfo,
                    ...res.data
                }
                userInfo.userNameHide = userInfo.realName.substring(0, 8) + '...';
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
            }).catch(err => {
                console.log(err)
            })
        },
        aa() {
            sendLoginMessage();
        },
        sendMessage() {
            if (!this.phone) {
                this.$toast.error('请输入手机号码');
                return
            }
            const phone = this.phone.replace(/\s/g, '')
            if (!phoneReg.test(phone)) {
                this.$toast.error('请输入正确的手机号码');
                return
            }
            this.disableMessage = true;
            sendLoginMessage({
                phone:this.phone
            }).then(data=>{
                console.log(data);
                this.key = data.data;
                this.disableMessage = true;
                this.auth_time = 30;
                this.messageInfo = this.auth_time + '秒后重试';
                var auth_timetimer = setInterval(() => {
                    this.auth_time--;
                    this.messageInfo = this.auth_time + '秒后重试';
                    if (this.auth_time <= 0) {
                        this.disableMessage = false;
                        this.messageInfo = '发送验证码';
                        clearInterval(auth_timetimer);
                    }
                }, 1000);
                
            }).catch(msg=>{
                this.disableMessage = false;
                this.$toast.error(msg.msg);
                console.log(msg);
            })
            
        }
    }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
    width: 100%;
    height: 100%;
    background: url('../../assets/images/login.jpg') no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    .login-content {
        width: 85%;
        height: 215px;
        background: rgba(255,255,255, 1);
        border-radius: 10px;
        position: relative;
        padding-top: 40px;
        & > div {
            margin-left: 15px;
            margin-right: 15px;
        }
        &::after {
            content: '';
            display: block;
            width: 70%;
            height: 25px;
            position: absolute;
            left: 15%;
            bottom: -25px;
            background: rgba(255,255,255,1);
            border-radius: 0 0 25px 25px;
            z-index: 0;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            background: rgba(255,255,255,1);
            border-radius: 18px 18px 0 0;
            width: 140px;
            height: 18px;
            top: -17px;
            left: 50%;
            margin-left: -70px;
        }
        p {
            position: absolute;
            left: 50%;
            width: 140px;
            text-align: center;
            margin-left: -70px;
            top: -17px;
            font-size: 20px;
            display: inline-block;
            padding: 5px 0;
        }
        .am-input-item {
            width: 100%;
            height: 44px;
            margin-bottom: 15px;
            border-radius: 20px;
            border: 1px solid #dedede;
            box-sizing: border-box;
            padding: 0 20px;
            & >>> .am-list-line {
                padding-right: 0;
                border: none;
                &::after {
                    display: none;
                }
                .am-input-label{
                    width: 65px;
                    border-right: 1px solid #dedede;
                    font-size: 15px;
                    margin-right: 16px;
                }
            } 
        }
        .login-validate-code {
            display: flex;
            flex-wrap: nowrap;
            .am-input-item {
                border-radius: 22px 0 0 22px;
                border-right: none;
            }
            & > div {
                flex: 0 0 auto;
                padding: 0;
                width: 85px;
                height: 44px;
                line-height: 44px;
                border-radius: 0 22px 22px 0;
            }
        }
        .button-login {
            width: 67%;
            position: absolute;
            left: 16.5%;
            bottom: -20px;
            z-index: 1;
            height: 47px;
        }
        .forget-password {
            text-align: right;
            margin-right: 16px;
            font-size: 13px;
            a {
                color: #969696;
            }
        }
    }
}
</style>